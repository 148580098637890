/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, useEffect } from 'react'
import useCurrentUser from '../hooks/useCurrentUser'
import { X_AUTH_TOKEN } from './CONSTANT_HEADERS'
import { readToken } from './JWTToken'
import { getCookie } from '../utillities/Cookies'

const GleapWidget: FC = () => {
  const token = getCookie(X_AUTH_TOKEN)
  const jwt = readToken(token)
  const [currentUser] = useCurrentUser()

  useEffect(() => {
    if (jwt && jwt.user_type !== undefined) {
      if (currentUser) {
        //@ts-ignore
        if (typeof window?.Gleap === 'function') {
          //@ts-ignore
          window?.Gleap.identify(
            currentUser?.id,
            {
              name: currentUser?.full_name,
              email: currentUser?.email
            },
            'GENERATED_USER_HASH'
          )
          //@ts-ignore
          window?.Gleap.registerCustomAction((customAction) => {
            if (customAction.name === 'openCrispChat') {
              if (window.$crisp) {
                window.$crisp.push(['do', 'chat:open'])
              }
            }
          })
        }
      }
    }

    return () => {
      // consolog aim to destroy
    }
  }, [jwt?.user_type, currentUser])
  return <></>
}
export default GleapWidget
