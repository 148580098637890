import Head from 'next/head'
import Footer from '../components/Footer'
import Header from '../components/Header'
import env from '../env'
import Script from 'next/script'
import CrispChat from '../utillities/CrispChat'
import { ResetStyle } from './reset.style'
import GleapWidget from '../utillities/GleapWidget'
import { getClient } from '../utillities/getIsServer'

const withLayout = (Page?: any, className?: string, resetStyle = false) => {
  const HOCCom = (props: any) => {
    const NotificationComponents = props.NotificationComponents

    return (
      <>
        {resetStyle && (
          <style jsx global>
            {ResetStyle}
          </style>
        )}
        <Head>
          <link rel="apple-touch-icon" href={`${env.PUBLIC_APP_DOMAIN}/img/apple-touch-icon.png`} />
          <link rel="shortcut icon" type="image/png" sizes="32x32" href="/favicon_32x32.png"></link>
          <link rel="shortcut icon" type="image/png" sizes="16x16" href="/favicon_16x16.png"></link>
          <link rel="preconnect" href="https://fonts.gstatic.com"></link>
          <link
            href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap"
            rel="stylesheet"
            media="all"
            // eslint-disable-next-line react/no-unknown-property
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            // onLoad="this.media='all'"
          />
        </Head>
        <div className={className || 'ant-layout company-layout'}>
          <Header NotificationComponents={NotificationComponents} />
          <div className="container">
            <Page {...props} />
          </div>
          <Footer />
        </div>
        {env.PUBLIC_GTM_KEY && (
          <Script
            id="gtm-script"
            strategy="lazyOnload"
            dangerouslySetInnerHTML={{
              __html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer', '${env.PUBLIC_GTM_KEY}');
            `
            }}
          />
        )}
        {env.PUBLIC_GTM_KEY && (
          <noscript>
            <iframe
              src={`https://www.googletagmanager.com/ns.html?id=${env.PUBLIC_GTM_KEY}`}
              height="0"
              width="0"
              style={{ display: 'none', visibility: 'hidden' }}></iframe>
          </noscript>
        )}
        {env.PUBLIC_ENABLE_LIVE_CHAT === 'true' && <CrispChat />}
        {env.NEXT_PUBLIC_ENABLE_GLEAP && getClient() && (
          <>
            {!window?.Gleap ? (
              <>
                <Script
                  strategy="lazyOnload"
                  dangerouslySetInnerHTML={{
                    __html: `!function(Gleap,t,i){if(!(Gleap=window.Gleap=window.Gleap||[]).invoked){for(window.GleapActions=[],Gleap.invoked=!0,Gleap.methods=["identify","clearIdentity","attachCustomData","setCustomData","removeCustomData","clearCustomData","registerCustomAction","logEvent","sendSilentCrashReport","startFeedbackFlow","setAppBuildNumber","setAppVersionCode","preFillForm","setApiUrl","setFrameUrl","isOpened","open","close","on","setLanguage","setOfflineMode","initialize"],Gleap.f=function(e){return function(){var t=Array.prototype.slice.call(arguments);window.GleapActions.push({e:e,a:t})}},t=0;t<Gleap.methods.length;t++)Gleap[i=Gleap.methods[t]]=Gleap.f(i);Gleap.load=function(){var t=document.getElementsByTagName("head")[0],i=document.createElement("script");i.type="text/javascript",i.async=!0,i.src="https://js.gleap.io/latest/index.js",t.appendChild(i)},Gleap.load(),
            Gleap.initialize("${env.NEXT_PUBLIC_ENABLE_GLEAP}")}}()`
                  }}
                />
                <GleapWidget />
              </>
            ) : null}
          </>
        )}
      </>
    )
  }

  HOCCom.getInitialProps = Page.getInitialProps
  HOCCom.getStaticProps = Page.getStaticProps
  return HOCCom
}

export default withLayout
